import React, {useState} from "react";
import '../../sass/trophies.css';

import trophy0 from '../../img/trophies/trophy0.svg';
import trophy1 from '../../img/trophies/trophy1.svg';
import trophy2 from '../../img/trophies/trophy2.svg';
import trophy3 from '../../img/trophies/trophy3.svg';
import trophy4 from '../../img/trophies/trophy4.svg';
import trophy5 from '../../img/trophies/trophy5.svg';
import trophy6 from '../../img/trophies/trophy6.svg';
import trophy7 from '../../img/trophies/trophy7.svg';
import trophy8 from '../../img/trophies/trophy8.svg';
import trophy9 from '../../img/trophies/trophy9.svg';
import trophy10 from '../../img/trophies/trophy10.svg';
import trophy11 from '../../img/trophies/trophy11.svg';
import trophy12 from '../../img/trophies/trophy12.svg';
import trophy13 from '../../img/trophies/trophy13.svg';
import trophy14 from '../../img/trophies/trophy14.svg';
import trophy15 from '../../img/trophies/trophy15.svg';
import trophy16 from '../../img/trophies/trophy16.svg';
import trophy17 from '../../img/trophies/trophy17.svg';
import trophy18 from '../../img/trophies/trophy18.svg';
import trophy19 from '../../img/trophies/trophy19.svg';
import trophy20 from '../../img/trophies/trophy20.svg';

    const trophies = [
        trophy0, trophy1, trophy2, trophy3, trophy4, trophy5, trophy6, trophy7, trophy8, trophy9, trophy10, trophy11, trophy12, trophy13, trophy14, trophy15, trophy16, trophy17, trophy18, trophy19, trophy20
    ];

    const Trophies = () => {
        const [selectedTrophy, setSelectedTrophy] = useState(null);

        const handleTrophyClick = (index) => {
            setSelectedTrophy(index);
        };

        const handleCloseModal = () => {
            setSelectedTrophy(null);
        };

        return (
            <div className="trophiesContainer">
                {trophies.map((trophy, index) => (
                    <img
                        key={index}
                        src={trophy}
                        alt={`Trophy ${index + 1}`}
                        className="trophyImage"
                        onClick={() => handleTrophyClick(index)}
                    />
                ))}
                {selectedTrophy !== null && (
                    <div className="modal" onClick={handleCloseModal}>
                        <img
                            src={trophies[selectedTrophy]}
                            alt={`Trophy ${selectedTrophy + 1}`}
                            className="modalImage"
                        />
                    </div>
                )}
            </div>
    );
};

export default Trophies;
